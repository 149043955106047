import type { FEFeedItem } from '@tickrr/db';

import { logger } from '@tickrr/lib/logger';
import { type Writable, get, writable } from 'svelte/store';

import { getContextualStore, initContextualWritableStore } from './_internal';

export type FeedItemListStore = ReturnType<typeof createFeedItemListStore>;

export const GLOBAL_FEED_ITEM_LIST_STORE_KEY = 'feedItemListStore';

/**
 * Initializes the feed item list stores. These are writable stores used to
 * track our FeedItems on each page. The benefit of using a store, rather than simply passing
 * the items as props, is that any mutations made in the FeedItemViewerModal will be
 * reflected in the store, and thus, the page.
 *
 * This is needed because props passed to Skeleton modals and drawers cannot be bound.
 *
 * @returns A sveltekit store.
 *
 * @note Unlike most of our stores, this store does not need to be instantiated with ```initContextualWritableStore```
 * and retrieved with ```getContextualStore``` because these stores are instantiated at page level and passed
 * as props.
 *
 * @example
 *
 * ```svelte
 *
 * <script>
 * 	import { createFeedItemListStore } from "@tickrr/ui/stores";
 *
 * 	const feedItemListStore = createFeedItemListStore();
 * </script>
 *
 * <FeedItemList
 * 		...
 * 		...
 * 		on:click={() => {
 * 			openFeedItemViewer({
 * 			itemStore: feedItemListStore,
 * 			...
 * 			...
 * 		})
 * }}
 * />
 *
 * ```
 */
export function createFeedItemListStore(initialList: FEFeedItem[] = []) {
	const { set, subscribe, update }: Writable<FEFeedItem[]> = writable(initialList);

	return {
		clear: () => set([]),
		findItem: (id: string) => {
			const list = get({ subscribe });
			const item = list.find((i) => i.id === id);

			if (!item) {
				logger.error('Could not find item with id', id);
				return undefined;
			}

			return item;
		},
		set,
		subscribe,
		update
	};
}

export function initGlobalFeedItemListStore() {
	const initialVal: FEFeedItem[] = [];
	return initContextualWritableStore(
		GLOBAL_FEED_ITEM_LIST_STORE_KEY,
		initialVal,
		createFeedItemListStore
	);
}

export function getGlobalFeedItemListStore() {
	return getContextualStore<FEFeedItem[], FeedItemListStore>(GLOBAL_FEED_ITEM_LIST_STORE_KEY);
}
