import type { Writable } from 'svelte/store';

import { getContextualStore, initContextualWritableStore } from './_internal';

export const IS_MOBILE_STORE_KEY = 'isMobileStore';

export type IsMobileStore = Writable<boolean>;

export function initIsMobileStore(): IsMobileStore {
	return initContextualWritableStore(IS_MOBILE_STORE_KEY, false);
}

/**
 * Retrieves the `isMobileStore`.
 *
 * This can *ONLY* be called from the **top level** of components!
 *
 * @example
 * ```svelte
 * <script>
 * 	import { getisMobileStore } from "@tickrr/ui/stores";
 *
 * 	const isMobileStore = getIsMobileStore();
 *
 * 	isMobileStore.set(true);
 *
 * </script>
 * ```
 */
export function getIsMobileStore(): IsMobileStore {
	return getContextualStore<boolean>(IS_MOBILE_STORE_KEY);
}
