import type { Writable } from 'svelte/store';

import { getContextualStore, initContextualWritableStore } from './_internal';

export const IS_PWA_STORE_KEY = 'isPwaStore';

export type IsPwaStore = Writable<boolean>;

/**
 * Initializes the isPwa store.
 * @returns A writable store for the isPwa state.
 */
export function initIsPwaStore(): IsPwaStore {
	return initContextualWritableStore(IS_PWA_STORE_KEY, false);
}

/**
 * Retrieves the `isPwaStore`.
 *
 * This can *ONLY* be called from the **top level** of components!
 *
 * @example
 * ```svelte
 * <script>
 * 	import { getisPwaStore } from "@tickrr/ui/stores";
 *
 * 	const isPwaStore = getIsPwaStore();
 *
 * 	isPwaStore.set(true);
 * </script>
 * ```
 */
export function getIsPwaStore(): IsPwaStore {
	return getContextualStore<boolean>(IS_PWA_STORE_KEY);
}
