import type { Writable } from 'svelte/store';

import type { IDB } from '../idb';

import { getContextualStore, initContextualWritableStore } from './_internal';

export const IDB_STORE_KEY = 'idbStore';

export type IDBStore = Writable<IDB>;

export function initIDBStore(): IDBStore {
	return initContextualWritableStore(IDB_STORE_KEY, null);
}

/**
 * Retrieves the `idbStore`.
 *
 * This can *ONLY* be called from the **top level** of components!
 *
 * @example
 * ```svelte
 * <script>
 * 	import { getIdbStore } from "@tickrr/ui/stores";
 *
 * 	const idbStore = getIdbStore();
 *
 * 	const idb = useIDB();
 * 	idbStore.set(idb);
 *
 * </script>
 * ```
 */
export function getIDBStore(): IDBStore {
	return getContextualStore(IDB_STORE_KEY);
}
