/**
 * @deprecated Sidebar width is now longer toggle-able (it remains at a fixed width).
 */
import type { Writable } from 'svelte/store';

import { getContextualStore, initContextualLocalStorageStore } from './_internal';

export const IS_DESKTOP_SIDEBAR_EXPANDED_STORE_KEY = 'isDesktopSidebarExpandedStore';

export type IsDesktopSidebarExpandedStore = Writable<boolean>;

export function initIsDesktopSidebarExpanded(): IsDesktopSidebarExpandedStore {
	return initContextualLocalStorageStore(IS_DESKTOP_SIDEBAR_EXPANDED_STORE_KEY, true);
}

/**
 * Retrieves the `isDesktopSidebarExpandedStore`.
 *
 * This can *ONLY* be called from the **top level** of components!
 *
 * @note The option is preserved and restored from local storage.
 *
 * @example
 * ```svelte
 * <script>
 * 	import { getisDesktopSidebarExpandedStore } from "@tickrr/ui/stores";
 *
 * 	const isDesktopSidebarExpandedStore = getisDesktopSidebarExpandedStore();
 *
 * 	isDesktopSidebarExpandedStore.set(true);
 * </script>
 * ```
 */
export function getIsDesktopSidebarExpandedStore(): IsDesktopSidebarExpandedStore {
	return getContextualStore<boolean>(IS_DESKTOP_SIDEBAR_EXPANDED_STORE_KEY);
}
