import type { TDTypes } from '@tickrr/twelvedata';

import { type Writable, writable } from 'svelte/store';

import { getContextualStore, initContextualWritableStore } from './_internal';

export type ActiveTimeSeriesStore = ReturnType<typeof createActiveSeriesStore>;

export const ACTIVE_TIME_SERIES_STORE_KEY = 'activeTimeSeriesStore';

/**
 * Initializes a store that holds the active series for the charts page.
 * This is used to make it easier to pass data around on the /charts page.
 *
 * @returns A sveltekit store.
 */
export function createActiveSeriesStore(initialList: TDTypes.TDValidatedTimeSeries[] = []) {
	const { set, subscribe, update }: Writable<TDTypes.TDValidatedTimeSeries[]> =
		writable(initialList);

	return {
		clear: () => set([]),
		set,
		subscribe,
		update
	};
}

export function initActiveTimeSeriesStore() {
	const initialVal: TDTypes.TDValidatedTimeSeries[] = [];
	return initContextualWritableStore(
		ACTIVE_TIME_SERIES_STORE_KEY,
		initialVal,
		createActiveSeriesStore
	);
}

export function getActiveTimeSeriesStore() {
	return getContextualStore<TDTypes.TDValidatedTimeSeries[], ActiveTimeSeriesStore>(
		ACTIVE_TIME_SERIES_STORE_KEY
	);
}
