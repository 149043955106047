import type { Writable } from 'svelte/store';

import { getContextualStore, initContextualWritableStore } from './_internal';

export const IS_EXTENSION_STORE_KEY = 'isExtensionStore';

export type IsExtensionStore = Writable<boolean>;

export function initIsExtensionStore(): IsExtensionStore {
	return initContextualWritableStore(IS_EXTENSION_STORE_KEY, false);
}

/**
 * Retrieves the `isExtensionStore`.
 *
 * This can *ONLY* be called from the **top level** of components!
 *
 * @example
 * ```svelte
 * <script>
 * 	import { getisExtensionStore } from "@tickrr/ui/stores";
 *
 * 	const isExtensionStore = getisExtensionStore();
 *
 * 	isExtensionStore.set(true);
 *
 * </script>
 * ```
 */
export function getIsExtensionStore(): IsExtensionStore {
	return getContextualStore<boolean>(IS_EXTENSION_STORE_KEY);
}
