import type { Readable } from 'svelte/store';

import { getContext } from 'svelte';

import { RtpWebsocketClient } from '../websockets/RTPWebsocketClient.ts';
import { initContextualReadableStore } from './_internal';

export const RTP_WEBSOCKET_CLIENT_STORE_KEY = 'rtpWebsocketClientStore';

export type RtpWebsocketClientStore = Readable<RtpWebsocketClient>;

export function initRtpWebsocketClientStore(pyServerWsUrl: string) {
	const client = new RtpWebsocketClient(pyServerWsUrl);
	return initContextualReadableStore(RTP_WEBSOCKET_CLIENT_STORE_KEY, client);
}

export function getRtpWebsocketClientStore() {
	const store = getContext<RtpWebsocketClientStore>(RTP_WEBSOCKET_CLIENT_STORE_KEY);

	if (!store)
		throw new Error(
			`Store with key: ${RTP_WEBSOCKET_CLIENT_STORE_KEY},  is not initialized. Please ensure that \`initializeStores()\` is invoked in the root layout file of this app!`
		);

	return store;
}
