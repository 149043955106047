import type { Readable } from 'svelte/store';

import { getContext } from 'svelte';

import { NotificationsWebsocketClient } from '../websockets/NotificationsWebsocketClient.ts';
import { initContextualReadableStore } from './_internal.ts';

export const NOTIFICATIONS_WEBSOCKET_CLIENT_STORE_KEY = 'notificationsWebsocketClientStore';

export type NotificationsWebsocketClientStore = Readable<NotificationsWebsocketClient>;

export function initNotificationsWebsocketClientStore(pyServerWsUrl: string) {
	const client = new NotificationsWebsocketClient(pyServerWsUrl);
	return initContextualReadableStore(NOTIFICATIONS_WEBSOCKET_CLIENT_STORE_KEY, client);
}

export function getNotificationsWebsocketClientStore() {
	const store = getContext<NotificationsWebsocketClientStore>(
		NOTIFICATIONS_WEBSOCKET_CLIENT_STORE_KEY
	);

	if (!store)
		throw new Error(
			`Store with key: ${NOTIFICATIONS_WEBSOCKET_CLIENT_STORE_KEY},  is not initialized. Please ensure that \`initializeStores()\` is invoked in the root layout file of this app!`
		);

	return store;
}
