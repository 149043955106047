import { ACTIVE_TIME_SERIES_STORE_KEY, getActiveTimeSeriesStore } from './activeTimeSeriesStore.ts';
import {
	GLOBAL_FEED_ITEM_LIST_STORE_KEY,
	getGlobalFeedItemListStore
} from './feedItemListStore.ts';
import { IDB_STORE_KEY, getIDBStore } from './idb.ts';
import { getRtpWebsocketClientStore } from './index.ts';
import { IS_EXTENSION_STORE_KEY, getIsExtensionStore } from './isExtension.ts';
import { IS_MACINTOSH_STORE_KEY, getisMacintoshStore } from './isMacintosh.ts';
import { IS_MOBILE_STORE_KEY, getIsMobileStore } from './isMobile.ts';
import { IS_PWA_STORE_KEY, getIsPwaStore } from './isPwa.ts';
import {
	IS_DESKTOP_SIDEBAR_EXPANDED_STORE_KEY,
	getIsDesktopSidebarExpandedStore
} from './isSidebarExpanded.ts';
import {
	IS_WEBSHARE_SUPPORTED_STORE_KEY,
	getIsWebshareSupportedStore
} from './isWebshareSupported.ts';
import {
	NOTIFICATIONS_WEBSOCKET_CLIENT_STORE_KEY,
	getNotificationsWebsocketClientStore
} from './notificationsWebsocketClient.ts';
import { SUPABASE_CLIENT_STORE_KEY, getSbStore } from './sb.ts';
import { SEARCH_HISTORY_STORE_KEY, getSearchHistoryStore } from './searchHistory.ts';
import { SETTINGS_STORE_KEY, getSettingsStore } from './settings.ts';
import { WATCHLIST_STORE_KEY, getWatchlistStore } from './watchlistStore.ts';

const STORE_DIRECTORY = {
	[ACTIVE_TIME_SERIES_STORE_KEY]: getActiveTimeSeriesStore,
	[GLOBAL_FEED_ITEM_LIST_STORE_KEY]: getGlobalFeedItemListStore,
	[IDB_STORE_KEY]: getIDBStore,
	[IS_DESKTOP_SIDEBAR_EXPANDED_STORE_KEY]: getIsDesktopSidebarExpandedStore,
	[IS_EXTENSION_STORE_KEY]: getIsExtensionStore,
	[IS_MACINTOSH_STORE_KEY]: getisMacintoshStore, // 'isMacintoshStore' is an alias for 'isMobileStore
	[IS_MOBILE_STORE_KEY]: getIsMobileStore,
	[IS_PWA_STORE_KEY]: getIsPwaStore,
	[IS_WEBSHARE_SUPPORTED_STORE_KEY]: getIsWebshareSupportedStore,
	[NOTIFICATIONS_WEBSOCKET_CLIENT_STORE_KEY]: getNotificationsWebsocketClientStore,
	[SEARCH_HISTORY_STORE_KEY]: getSearchHistoryStore,
	[SETTINGS_STORE_KEY]: getSettingsStore,
	[SUPABASE_CLIENT_STORE_KEY]: getSbStore,
	[WATCHLIST_STORE_KEY]: getWatchlistStore,
	// FIXME: im not sure why, but we cannot import the store key here
	// FIXME: ```can't access lexical declaration 'RTP_WEBSOCKET_CLIENT_STORE_KEY' before initialization```
	['rtpWebsocketClientStore']: getRtpWebsocketClientStore
};

type StoreKeyToStoreMap = typeof STORE_DIRECTORY;

type ValidKey = keyof StoreKeyToStoreMap;

type Stores<K extends ValidKey[]> = { [P in K[number]]: ReturnType<StoreKeyToStoreMap[P]> };

/**
 * Retrieves the stores corresponding to the given keys.
 *
 * @template K - The type of the keys.
 * @param keys - An array of keys representing the stores to retrieve.
 * @returns An object containing the retrieved stores.
 *
 * @example
 * ```svelte
 * <script>
 *     import { getStores, IS_MOBILE_STORE_KEY, IS_PWA_STORE_KEY } from "@tickrr/ui/stores";
 *
 *    const { isMobileStore, isPwaStore } = getStores([IS_MOBILE_STORE_KEY, IS_PWA_STORE_KEY]);
 * </script>
 * ```
 *
 * Or, since the keys are type-safe, you can simply do:
 *
 * ```svelte
 * <script>
 *    import { getStores } from "@tickrr/ui/stores";
 *
 *   const { isMobileStore, isPwaStore } = getStores(['isMobileStore', 'isPwaStore']);
 * </script>
 * ```
 */
export function getStores<K extends ValidKey[]>(keys: K): Stores<K> {
	return keys.reduce((acc: Stores<K>, key) => {
		if (!Object.prototype.hasOwnProperty.call(STORE_DIRECTORY, key)) {
			throw new Error(
				`Store with key ${key} has not been initalized. Are you sure you initStores() has been called in the root layout?`
			);
		}
		const getter = STORE_DIRECTORY[key];
		const store = getter();
		return { ...acc, [key]: store };
	}, {} as Stores<K>);
}
