import type { Writable } from 'svelte/store';

import { getContextualStore, initContextualWritableStore } from './_internal';

export const IS_MACINTOSH_STORE_KEY = 'isMacintoshStore';

export type IsMacintoshStore = Writable<boolean>;

export function initIsMacintoshStore(): IsMacintoshStore {
	return initContextualWritableStore(IS_MACINTOSH_STORE_KEY, false);
}

/**
 * Retrieves the `isMacintoshStore`.
 *
 * This can *ONLY* be called from the **top level** of components!
 *
 * @example
 * ```svelte
 * <script>
 * 	import { getisMacintoshStore } from "@tickrr/ui/stores";
 *
 * 	const isMacintoshStore = getIsMobileStore();
 *
 * 	isMacintoshStore.set(true);
 *
 * </script>
 * ```
 */
export function getisMacintoshStore(): IsMacintoshStore {
	return getContextualStore<boolean>(IS_MACINTOSH_STORE_KEY);
}
