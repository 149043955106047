import type { SupabaseClient } from '@supabase/supabase-js';
import type { Writable } from 'svelte/store';

import { getContextualStore, initContextualWritableStore } from './_internal';

export const SUPABASE_CLIENT_STORE_KEY = 'sbStore';

export type SbStore = Writable<SupabaseClient | null>;

/**
 * Initializes the supabase client store.
 * @returns A writable store.
 */
export function initSbStore(): SbStore {
	return initContextualWritableStore(SUPABASE_CLIENT_STORE_KEY, null);
}

/**
 * Retrieves the `sbStore`.
 *
 * This can *ONLY* be called from the **top level** of components!
 *
 * @example
 * ```svelte
 * <script>
 * 	import { getSbStore } from "@tickrr/ui/stores";
 *
 * 	const sbStore = getSbStore();
 *
 * 	sbStore.set(true);
 * </script>
 * ```
 */
export function getSbStore(): SbStore {
	return getContextualStore<SupabaseClient | null>(SUPABASE_CLIENT_STORE_KEY);
}
