import { localStorageStore } from '@skeletonlabs/skeleton';

import type { CustomWritableInitFn } from '../types/CustomWritable';
import type { SearchHistory } from '../types/SearchHistory';

import { getContextualStore, initContextualWritableStore } from './_internal';

export const SEARCH_HISTORY_STORE_KEY = 'searchHistoryStore';
const DEFAULT_STORE_VALUE: SearchHistory = [];
const MAX_SEARCH_HISTORY_LENGTH = 10;

export type SearchHistoryStore = ReturnType<typeof generateSearchHistoryStore>;

const generateSearchHistoryStore: CustomWritableInitFn<
	SearchHistory,
	{
		add: (item: SearchHistory[number]) => void;
	}
> = (initialValue) => {
	const store = localStorageStore<SearchHistory>(
		`tickrr.${SEARCH_HISTORY_STORE_KEY}`,
		initialValue ?? []
	);
	return {
		...store,
		add: (item) => {
			store.update((history) => [item, ...history].slice(0, MAX_SEARCH_HISTORY_LENGTH));
		},
		update: (updater) => {
			store.update(updater);
		}
	};
};

/**
 * Initializes the search history store.
 * @returns A writable store.
 */
export function initSearchHistoryStore(): SearchHistoryStore {
	return initContextualWritableStore(
		SEARCH_HISTORY_STORE_KEY,
		DEFAULT_STORE_VALUE,
		generateSearchHistoryStore
	);
}

/**
 * Retrieves the `searchHistoryStore`.
 *
 * This can *ONLY* be called from the **top level** of components!
 *
 * @example
 * ```svelte
 * <script>
 * 	import { getSearchHistoryStore } from "@tickrr/ui/stores";
 *
 * 	const searchHistoryStore = getSearchHistoryStore();
 *
 * 	searchHistoryStore.set(true);
 * </script>
 * ```
 */
export function getSearchHistoryStore(): SearchHistoryStore {
	return getContextualStore<SearchHistory, SearchHistoryStore>(SEARCH_HISTORY_STORE_KEY);
}
