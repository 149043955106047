import type { Writable } from 'svelte/store';

import { getContextualStore, initContextualLocalStorageStore } from './_internal';

export const IS_WEBSHARE_SUPPORTED_STORE_KEY = 'isWebshareSupportedStore';

export type IsWebshareSupportedStore = Writable<boolean>;

export function initIsWebshareSupportedStore(): IsWebshareSupportedStore {
	return initContextualLocalStorageStore(IS_WEBSHARE_SUPPORTED_STORE_KEY, false);
}

/**
 * Retrieves the `isWebshareSupportedStore`.
 *
 * This can *ONLY* be called from the **top level** of components!
 *
 * @example
 * ```svelte
 * <script>
 * 	import { getisWebshareSupportedStore } from "@tickrr/ui/stores";
 *
 * 	const isWebshareSupportedStore = getisWebshareSupportedStore();
 *
 * 	isWebshareSupportedStore.set(true);
 * </script>
 * ```
 */
export function getIsWebshareSupportedStore(): IsWebshareSupportedStore {
	return getContextualStore<boolean>(IS_WEBSHARE_SUPPORTED_STORE_KEY);
}
