import type { FEWatchlist } from '@tickrr/db';

import { type Writable } from 'svelte/store';

import { getContextualStore, initContextualWritableStore } from './_internal';

export type WatchlistStore = Writable<FEWatchlist>;

export const WATCHLIST_STORE_KEY = 'watchlistStore';

export function initWatchlistStore() {
	const initialVal: FEWatchlist = [];
	return initContextualWritableStore(WATCHLIST_STORE_KEY, initialVal);
}

export function getWatchlistStore() {
	return getContextualStore<FEWatchlist, WatchlistStore>(WATCHLIST_STORE_KEY);
}
